import type { CollectionDetailFragment, CollectionListOptions, CollectionsQuery } from '#graphql-operations'
import { LIST_QUERY_LIMIT } from '~/constants'

export function fetchTopLevelCollections(options: CollectionListOptions = {}) {
  const state = useStore()

  return reactiveLoad<CollectionsQuery['collections']['items']>(
    () => state.value.collections as any,
    (items) => {
      state.value.collections = items
    },
    () => useGraphqlQuery('collections', {
      options: {
        take: LIST_QUERY_LIMIT,
        topLevelOnly: true,
      },
    }).then(result => result.data.collections.items),
  )
}

export async function fetchChildCollections(options: CollectionListOptions = {}) {
  const state = useStore()

  const parentIds = state.value.collections?.filter(collection => collection.parent?.name === '__root_collection__').map(collection => collection.id)

  let result = await useGraphqlQuery('collections', {
    options: {
      skip: 0,
      take: LIST_QUERY_LIMIT,
      filter: {
        parentId: { in: parentIds },
      },
    },
  })

  const totalItems = result.data.collections.totalItems
  const totalBatches = Math.ceil(totalItems / LIST_QUERY_LIMIT)

  const existingIds = new Set(state.value.collections?.map(collection => collection.id))

  for (let batch = 1; batch < totalBatches; batch++) {
    const skip = batch * LIST_QUERY_LIMIT

    result = await useGraphqlQuery('collections', {
      options: {
        skip,
        take: LIST_QUERY_LIMIT,
        filter: {
          parentId: { in: parentIds },
        },
      },
    })

    const filteredCollections = result.data.collections.items.filter(collection => !existingIds.has(collection.id))
  state.value.collections?.push(...filteredCollections)

    for (const collection of filteredCollections)
      existingIds.add(collection.id)
  }
}

export function fetchCollection(id: string) {
  const state = useStore()

  return reactiveLoad<CollectionDetailFragment | undefined | null>(
    () => state.value.collection?.[id],
    (item) => {
      state.value.collection ??= {}
      state.value.collection[id] = item
    },
    () => useGraphqlQuery('collection', { id }).then(result => result.data.collection),
  )
}
